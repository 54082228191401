import {
  SINGLE_SERVICE_EDITOR_X_PRESET_ID,
  SINGLE_SERVICE_PRESET_ID,
} from '../consts';
import {
  AlignmentOptions,
  ImagePositionOptions,
  ImageSizeOptions,
} from '../types/types';

export const getServiceImagePositionDefaultValue = () =>
  ImagePositionOptions.LEFT;
export const getAlternateImageInfoSidesDefaultValue = () => false;
export const getTitleAlignmentDefaultValue = () => AlignmentOptions.CENTER;
export const getServiceImageSizeDefaultValue = (isMobile: boolean) =>
  isMobile ? ImageSizeOptions.MEDIUM : ImageSizeOptions.SMALL;
export const getTextAlignmentDefaultValue = (
  preset: any,
  isMobile: boolean,
) => {
  if (isMobile) {
    return AlignmentOptions.LEFT;
  }
  switch (preset) {
    case SINGLE_SERVICE_PRESET_ID:
    case SINGLE_SERVICE_EDITOR_X_PRESET_ID:
      return AlignmentOptions.CENTER;
    default:
      return AlignmentOptions.LEFT;
  }
};
